import { template as template_8847032ee4af4516bccc282d57afe0bd } from "@ember/template-compiler";
const FKControlMenuContainer = template_8847032ee4af4516bccc282d57afe0bd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
