import { template as template_fa6ccc6219184cd1bdf69055bf04777b } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_fa6ccc6219184cd1bdf69055bf04777b(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
