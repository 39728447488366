import { template as template_3904e1e21caa4c5a8375d524b5b8c3a8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3904e1e21caa4c5a8375d524b5b8c3a8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
