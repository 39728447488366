import { template as template_084f607c860e47a7aece94b9510955a5 } from "@ember/template-compiler";
const WelcomeHeader = template_084f607c860e47a7aece94b9510955a5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
