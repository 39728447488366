import { template as template_e22a1a56b18f4e6fa76330849a899ba9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e22a1a56b18f4e6fa76330849a899ba9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
