import { template as template_a0b65dde783b4e26b49b0e532d99d094 } from "@ember/template-compiler";
const FKText = template_a0b65dde783b4e26b49b0e532d99d094(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
